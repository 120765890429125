.profilePicture {
    height: 32px;
    border-radius: 50%;
    margin-right: 0.5rem;
}


.postHead {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.usernameText {
    margin: 0;
    font-weight: bold;
}

.postContainer {
    padding: 10px;
    background-color: #ffffff;
    margin-top: 1rem;
}

.contentPicture {
    object-fit: contain;
    width: 100%;
}

.heart {
    font-size: 30px;
    cursor: pointer;
    color: black;
    margin-right: .5rem;
}

.heart:active {
    transform: scale(90%);
    color: red;
}

.postFooter > button {
    background-color:transparent;
    border-width: 0;
    padding: 0;
}

.postFooter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.postFooter > p,.title  {
    font-weight: bold;
}

@media (min-width: 768px) {
    .contentPicture {
        max-height: 500px;
    }
}