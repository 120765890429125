.dialogContainer {
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
    background-color: inherit;
}

.contentInput {
    padding: 20px 10px;
    resize: none;
    border: none;
    background-color: transparent;
    font: inherit;
}

.imageInput {
    border: none;
    padding: 10px;
    background-color: transparent;
    font: inherit;

}

.titleInput {
    border: none;
    padding: 10px;
    resize: none;
    background-color: transparent;
    font: inherit;

}

.hidden {
    display: none;
}

.cameraIcon {
    font-size: 30px;
    cursor: pointer;
    transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.cameraIcon:active {
    transform: scale(90%);
}

.lastRowContainer {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lastRowContainer > button {
    background-color: transparent;
    border-width: 0;
}

.postBtnContainer > button {
    cursor: pointer;
    padding: 10px;
    border-radius: 0.5rem;
    border-width: 0;
    background-color: rgb(248, 157, 82);
    color: white;
    font-size: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.postBtnContainer > button:active {
    transform: scale(90%);
}

.postBtnContainer > button:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.cardContainer {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 1.5rem;
    padding: 10px;
}

@media (min-width: 768px) {
    .cardContainer {
        width: 50%;
        margin: 0 auto;
    }
}
