Header {
    width: 100%;
    background-color: rgb(244, 129, 32);
}
.headerContainer {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
}

.appName {
    color: white;
    font-weight: 500;
    font-family: 'Dancing Script', cursive;
    font-size: 1.5rem;
}

.newPostBtn {
    cursor: pointer;
    padding: 10px;
    border-radius: 0.5rem;
    border-width: 0;
    background-color: white;
    font-size: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    transition-property: background-color, border-color, color, fill, stroke,
        opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.newPostBtn:active {
    transform: scale(90%);
}

.newPostBtn:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) {
    .headerContainer {
        width: 50%;
        margin: 0 auto;
    }
}
